import React from 'react'
import './Genesysservices.sass'
import building from 'img/AdobeStock_126197725_x3.jpg'

function Architecture(props) {
    return (
        <div className="page">
            <div className="stairs building" style={{ backgroundImage: `url(${building})` }} >
                <div className="cover cover-left"></div><div className="cover cover-right"></div>
                <div className="container servicesTitleCon d-flex align-items-center">
                    <div className="row">
                        <div className="col">
                            <div className="servicesTitle" dangerouslySetInnerHTML={{ __html: props.text.title }}>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container introBG">

                <div className="row">


                    <div className="col-12 col-lg-8 introText" dangerouslySetInnerHTML={{ __html: props.text.intro }}>
                    </div>
                    <div className="col">

                    </div>
                </div>
            </div>
            <div className="experienceBG">



                <div className="container ">

                    <div className="row ">
                        <div className="col-12 col-lg-8 experienceText" dangerouslySetInnerHTML={{ __html: props.text.experience }}>


                        </div>
                        <div className="col">

                        </div>

                    </div>
                </div>
            </div>


        </div>

    )

}

export default Architecture