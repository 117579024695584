import React from 'react'
import {arrowButton, arrowImage} from './ArrowButton.module.sass'

function ArrowButton(props){
    return (
        <div className={arrowButton} onClick={props.onClick} style={props.style}>
            <svg className={arrowImage} width="101" height="28" viewBox="0 0 101 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M83.3269 1L99.002 14L83.3269 27" stroke="#A01A6B" strokeWidth="2" />
                <line x1="97.3535" y1="13.7419" x2="-0.000335693" y2="13.7419" stroke="#A01A6B" strokeWidth="2" />
            </svg>
        </div>
    )
}

export default ArrowButton