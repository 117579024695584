import './App.sass';
import Frontpage from './App/Pages/Frontpage.js'
import Header from './App/Pages/Header.js'
import Product from './App/Pages/Product.js'
import Contactus from './App/Pages/Contactus.js'
import Aboutus from './App/Pages/Aboutus.js'
import Businessconsulting from './App/Pages/Businessconsulting.js'
import Architecture from './App/Pages/Architecture.js'
import Implementation from './App/Pages/Implementation.js'
import Customisation from './App/Pages/Customisation.js'
import Integration from './App/Pages/Integration.js'
import Support from './App/Pages/Support.js'
import Ohub from './App/Pages/OHub.js'
import {useEffect, useState} from 'react'
import fetchAppText from './fetch/fetchAppText.js'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { useLocation } from "react-router-dom";


function App() {
  const [text, setText] = useState(null)

  useEffect(()=>{
    fetchAppText(setText, 'en')
  }, [])
 
  
  if(text === null){  // Do not render app if app is waiting for text.
    return <></>
  }
  else{ // Render app when text is received.
    return (

      <Router >
        <ScrollToTopOnNewRoute />
        <Header text={text.header}/> 
        <Switch>
          <Route exact path="/">
            <Frontpage/>
            <Product text={text.product} />
          </Route>
          <Route exact path="/Businessconsulting">
              <Businessconsulting text={text.businessConsulting} />
          </Route>
          <Route exact path="/Architecture">
              <Architecture text={text.architecture} />
          </Route>
          <Route exact path="/Implementation">
              <Implementation text={text.implementation} />
          </Route>
          <Route exact path="/Customisation">
              <Customisation text={text.customisation} />
          </Route>
          <Route exact path="/Integration">
            <Integration text={text.integration} />
          </Route>
          <Route exact path="/Support">
            <Support text={text.support} />
          </Route>
          <Route exact path="/OperationHUB">
            <Ohub text= {text.ohub}/>
          </Route>
          <Route exact path="/Aboutus">
            <Aboutus text={text.aboutUs} />
          </Route>
          
          <Route>
            {/* Replace with unknown url page. */}
            <Frontpage/>
            <Product text={text.product} />
          </Route>
        </Switch>
        <Contactus text={text.contactUs}/>
      </Router>
    )
  }
}

export default App


function ScrollToTopOnNewRoute() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null
}
