import React from'react'
import './Aboutus.sass'
import aboutImg from 'img/AdobeStock_298891216_x3.jpg'
import vdphoto from 'img/IMG_2179-3_x3.jpg'
import ctophoto from 'img/IMG_2257-4_x3.jpg'

function Aboutus(props) {
    return (
        <div className="page">
            <div className="container">
                <div className="row">

                    <div className="servicesTitle" dangerouslySetInnerHTML={{ __html: props.text.pageTitle }} >

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 aboutUsText" dangerouslySetInnerHTML={{ __html: props.text.aboutUsText }}>

                    </div>
                </div>
            </div>

            <div className="container aboutusphoto">
                <div className="row">
                    <div className="col-12 col-lg-6 "  >
                        <div className="d-flex justify-content-center">

                        

                        <div className="vdphoto" style={{ backgroundImage: `url(${vdphoto})` }}>

                        </div>
                        </div>
                        <h1 className="phototextH">
                            Oskar Kåver 
                        </h1>
                        <p className="phototextC"> 
                            Co-founder and CEO
                        </p>
                       

                    </div>
                    <div className="col-12 col-lg-6 " >
                        <div className="d-flex justify-content-center">

                       
                        <div className="ctophoto" style={{ backgroundImage: `url(${ctophoto})` }}>

                        </div>
                        </div>
                        <h1 className="phototextH">
                            Fredrik Andersson

                        </h1>
                        <p className="phototextC"> 
                           Co-founder and CTO 
                        </p>

                    </div>
                </div>
            </div>


            <div id="aboutus" className="aboutus" style={{ backgroundImage: `url(${aboutImg})` }}>
                <div className="darken"></div>
                <div className="shimmer"></div>
                <div className="container conabout">
                    <div className="row row6">
                        <div className="col col61">
                            <p className="abouthead">
                                {props.text.title}

                            </p>

                        </div>
                    </div>
                    <div className="row row7">
                        <div className="col-12 col-lg-4 col71">
                            <p className="aboutext1">
                                {props.text.timelineText1}

                            </p>

                        </div>
                        <div className="col-12 col-lg-4 col72 d-none d-lg-block">
                            <div className="circlelane">

                            </div>
                            <div className="line1">

                            </div>
                        </div>
                        <div className="col col73 d-flex align-items-center justify-content-center">

                        </div>
                    </div>
                    <div className="row row8">
                        <div className="col-12 col-lg-4 col81">

                        </div>
                        <div className="col-12 col-lg-4 col82 d-none d-lg-block">
                            <div className="line2">

                            </div>
                            <div className="circlelane2">

                            </div>
                            <div className="line3">

                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col83 d-flex align-items-center justify-content-center">
                            <p className="aboutext2">
                                {props.text.timelineText2}
                            </p>
                        </div>
                    </div>
                    <div className="row row9">
                        <div className="col-12 col-lg-4 col91">
                            <p className="aboutext3 d-flex align-items-center justify-content-center">
                                {props.text.timelinetext3}
                            </p>
                        </div>
                        <div className="col-12 col-lg-4 col92 d-none d-lg-block">
                            <div className="line4">

                            </div>
                            <div className="circlelane3">

                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col93">

                        </div>
                    </div>
                </div>



            </div>
           
        </div>
     
    )
  }
  
  export default Aboutus