import React from'react'
import './Genesysservices.sass'
import support from 'img/AdobeStock_321604276_x3.jpg'

function  Support(props) {
    return (
        <div className="page">
            <div className="stairs support" style={{ backgroundImage: `url(${support})` }}>
                <div className="cover cover-left"></div><div className="cover cover-right"></div>

                <div className="container servicesTitleCon d-flex align-items-center">
                    <div className="row">
                        <div className="col">

                            <div className="servicesTitle" dangerouslySetInnerHTML={{ __html: props.text.title }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container introBG">

            <div className="row ">
                        <div className="col-12 col-lg-8 experienceText" dangerouslySetInnerHTML={{ __html: props.text.experience }}>


                        </div>
                        <div className="col">

                        </div>

                    </div>
            </div>
            


        </div>

    )

}

export default Support