import React from'react'
import './Genesysservices.sass'
import computer2 from 'img/AdobeStock_167781326_x3.jpg'


function Ohub(props) {
    return (
        <div className="page">
            <div className="stairs ohubPhoto" style={{ backgroundImage: `url(${computer2})` }}>
                <div className="cover cover-left"></div><div className="cover cover-right"></div>


                <div className="container servicesTitleCon d-flex align-items-center">
                    <div className="row">
                        <div className="col ohubcol">

                            <div className="servicesTitle" dangerouslySetInnerHTML={{ __html: props.text.title }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container introBG">

                <div className="row">


                    <div className="col-12 col-lg-8 introText" dangerouslySetInnerHTML={{ __html: props.text.intro }}>
                    </div>
                    <div className="col">
                        

                    </div>
                </div>
                <div className="row ">
                        <div className="col-12 col-lg-8 experienceText" dangerouslySetInnerHTML={{ __html: props.text.experience }}>


                        </div>
                        <div className="col">

                        </div>

                    </div>
            </div>
            
            <div className="container">
                <div className="row">
                    <div className="col">

                    <h2 className="ohtitle2" dangerouslySetInnerHTML={{ __html: props.text.title2 }}>

                    </h2>
                    </div>
                </div>
                <div className="row modules">
                    <div className="col-12 col-lg-6" >
                        <h2 dangerouslySetInnerHTML={{ __html: props.text.fbTitle }}>

                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: props.text.fbText }}>

                        </p>

                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center">
                            <svg className="callflowsvg" width="844" height="575" viewBox="0 0 844 575" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="270.745" y="5" width="476.547" height="307.918" rx="65" stroke="#1C1C1C" strokeWidth="10" />
                                <rect x="5" y="256.332" width="476.547" height="180.62" rx="87" stroke="#1C1C1C" strokeWidth="10" />
                                <rect x="362" y="389" width="476.547" height="180.62" rx="82" stroke="#1C1C1C" strokeWidth="10" />
                                <circle cx="634.874" cy="351.308" r="13.5" fill="#1C1C1C" />
                            </svg>

                        </div>

                    </div>
                </div>
                <div className="row modules flex-column-reverse flex-lg-row ">
                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center">
                        <svg className="tabletsvg" width="368" height="446" viewBox="0 0 368 446" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="45" y="41" width="279" height="357" rx="10" fill="black" />
                            <g filter="url(#filter0_d)">
                                <rect x="50" y="46" width="268" height="346" rx="7" fill="white" />
                                <rect x="70" y="74" width="167" height="24" rx="3" fill="#E4E4E4" />
                                <rect x="257" y="74" width="39" height="24" rx="3" fill="#E4E4E4" />
                                <rect x="257" y="239" width="39" height="24" rx="3" fill="#E4E4E4" />
                                <rect x="70" y="239" width="167" height="24" rx="3" fill="#E4E4E4" />
                                <rect x="248" y="343" width="48" height="16" rx="3" fill="#E4E4E4" />
                                <rect x="70" y="116" width="226" height="103" rx="3" fill="#E4E4E4" />
                            </g>
                            <path d="M265 85.5285L272.695 92L287 76" stroke="#46B4A0" strokeWidth="6" />
                            <path d="M265 251.529L272.695 258L287 242" stroke="#46B4A0" strokeWidth="6" />
                            <defs>
                                <filter id="filter0_d" x="0" y="0" width="368" height="446" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="25" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                        </div>



                    </div>
                    <div className="col-12 col-lg-6">
                        <h2 dangerouslySetInnerHTML={{ __html: props.text.sTitle }}>

                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: props.text.sText }}>

                        </p>

                    </div>
                </div>
                <div className="row modules">
                    <div className="col-12 col-lg-6">
                        <h2 dangerouslySetInnerHTML={{ __html: props.text.cTitle }}>

                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: props.text.cText }}>

                        </p>

                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center">
                        <svg className="arrowsvg" width="487" height="354" viewBox="0 0 487 354" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="18.0861" cy="124.189" rx="18.0861" ry="18.5664" fill="#B3AFAF" />
                            <ellipse cx="404.51" cy="177" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="340.106" cy="229.811" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="340.106" cy="177" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="340.106" cy="124.189" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="275.702" cy="282.623" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="275.702" cy="335.434" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="275.702" cy="71.3775" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="275.702" cy="18.5664" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <path d="M486.5 177C486.5 186.99 478.614 195.066 468.914 195.066C459.214 195.066 451.328 186.99 451.328 177C451.328 167.01 459.214 158.934 468.914 158.934C478.614 158.934 486.5 167.01 486.5 177Z" fill="#1C1C1C" stroke="black" />
                            <ellipse cx="404.51" cy="229.811" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="404.51" cy="124.189" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="340.106" cy="282.623" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="340.106" cy="71.3775" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="275.702" cy="229.811" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="275.702" cy="177" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="275.702" cy="124.189" rx="18.0861" ry="18.5664" fill="#1C1C1C" />
                            <ellipse cx="211.298" cy="229.811" rx="18.0861" ry="18.5664" fill="#4F4F4F" />
                            <ellipse cx="211.298" cy="177" rx="18.0861" ry="18.5664" fill="#4F4F4F" />
                            <ellipse cx="211.298" cy="124.189" rx="18.0861" ry="18.5664" fill="#4F4F4F" />
                            <ellipse cx="146.894" cy="229.811" rx="18.0861" ry="18.5664" fill="#6C6C6C" />
                            <ellipse cx="146.894" cy="177" rx="18.0861" ry="18.5664" fill="#6C6C6C" />
                            <ellipse cx="146.894" cy="124.189" rx="18.0861" ry="18.5664" fill="#6C6C6C" />
                            <ellipse cx="82.4901" cy="229.811" rx="18.0861" ry="18.5664" fill="#8F8F8F" />
                            <ellipse cx="82.4901" cy="177" rx="18.0861" ry="18.5664" fill="#8F8F8F" />
                            <ellipse cx="82.4901" cy="124.189" rx="18.0861" ry="18.5664" fill="#8F8F8F" />
                            <ellipse cx="18.0861" cy="229.811" rx="18.0861" ry="18.5664" fill="#B2AFAF" />
                            <ellipse cx="18.0861" cy="177" rx="18.0861" ry="18.5664" fill="#B2AFAF" />
                        </svg>
                        </div>


                    </div>
                </div>
                <div className="row modules flex-column-reverse flex-lg-row ">
                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center">
                            <svg width="182" height="263" viewBox="0 0 182 263" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="27" y="2" width="129" height="196" rx="64.5" stroke="black" strokeWidth="4" />
                                <line x1="90" y1="199" x2="90" y2="260" stroke="black" strokeWidth="4" />
                                <path d="M2 148C2 148 2 228.585 93.437 227.997C184.874 227.409 179.954 148 179.954 148" stroke="black" strokeWidth="4" />
                                <line x1="25" y1="261" x2="158" y2="261" stroke="black" strokeWidth="4" />
                                <line x1="145" y1="72.75" x2="36.9968" y2="72.75" stroke="black" strokeWidth="4" />
                            </svg>



                        </div>



                    </div>
                    <div className="col-12 col-lg-6">
                        <h2 dangerouslySetInnerHTML={{ __html: props.text.rTitle }}>

                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: props.text.rText }}>

                        </p>

                    </div>
                </div>
            </div>


        </div>


    )

}

export default Ohub