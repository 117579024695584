import React from'react'
import './Frontpage.sass'
import fpImg from 'img/AdobeStock_307092601_x3.jpg'
import smoothScrollIntoView from 'scroll-into-view-if-needed'

function Frontpage(props) {
    const scrollToId = (id) => {
        const page = document.getElementById(id)
        smoothScrollIntoView(page, {behavior: 'smooth', block: 'start'})
    }
    return (
        <div>

            <div className="frontpage" style={{ backgroundImage: `url(${fpImg})` }}>
                <button className="frontpageBtn" onClick={() => scrollToId('product')}>
                    <svg className="frontpageBtnSvg" width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34 1L17.5 18L1 1" stroke="white" stroke-width="2" />
                    </svg>


                </button>

            </div>
            {/* <div className="container">
                <div className="row">
                    <div dangerouslySetInnerHTML={{ __html: props.text.title1 }}>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                    <div dangerouslySetInnerHTML={{ __html: props.text.productText1 }}>
                    </div>
                    </div>
                    <div className="col">

                    </div>
                </div>
            </div>
            <div className="FpServicesTitle">
                <div className="container ">
                    <div className="row">
                        <div className="col-6">
                            <div dangerouslySetInnerHTML={{ __html: props.text.servicesTitle }}>
                            </div>
                        </div>
                        <div className="col-6">
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <div dangerouslySetInnerHTML={{ __html: props.text.ohTitle }}>
                        </div>
                    </div>
                </div>
            </div> */}






        </div>

       

    )
  }
  
  export default Frontpage