const fetchAppText = (setText, language = 'en') => {
    fetch(`${process.env.PUBLIC_URL}/text_${language}.json`)
      .then(response=>{
        if(response.status === 200){
          return response.json()
        }
        else{
          console.log(`Error occurred trying to fetch text. Http status code: (${response.status}). Re-trying in 2 seconds...`)
          return setTimeout(() => fetchAppText('en'), 2000);
        }
      })
      .then(json=>{
        setText(json)
      })
      .catch((error)=>{
        console.log('Error occurred trying to fetch text. Re-trying in 2 seconds...', error)
        
        return setTimeout(() => fetchAppText('en'), 2000);
      })
  }

export default fetchAppText